import process from 'process';

(window as any).dataLayer = (window as any).dataLayer || [];

function gtag(...args: unknown[]): void {
  (window as any).dataLayer.push(args);
}

gtag('js', new Date());

gtag('consent', 'default', {
  ad_personalization: 'denied',
  ad_user_data: 'denied',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
});

gtag('config', process.env.ENV === 'prod' ? 'G-4XTLXZDFXX' : 'G-FBF4ERS9QD');

const setStorageItem = (key: string, value: unknown): void => window.localStorage.setItem(key, JSON.stringify(value));

const getStorageItem = <T>(key: string): T => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

const removeStorageItem = (key: string): void => window.localStorage.removeItem(key);

const footer = (): void => {
  document.getElementById('year').innerText = new Date().getFullYear().toString();
};

const header = (): void => {
  const desktopView = (): boolean => window.innerWidth > 675;

  const header: HTMLElement = document.getElementById('header');

  const toggleStickyHeader = () => {
    if (window.scrollY > header.clientHeight) {
      header.classList.add('bg-white', 'shadow-lg');
      header.classList.remove('bg-accent-dark');

      return;
    }

    header.classList.add('bg-accent-dark');
    header.classList.remove('bg-white', 'shadow-lg');
  };

  window.addEventListener('scroll', toggleStickyHeader);

  toggleStickyHeader();

  const sideMenuTriggers: NodeListOf<Element> = document.querySelectorAll('.side-menu-trigger');

  sideMenuTriggers.forEach((sideMenuTrigger: Element) => {
    sideMenuTrigger.addEventListener('click', () => {
      header.classList.toggle('active');
      document.body.classList.toggle('max-venus:overflow-hidden');
    });
  });

  const sideMenuLinks: NodeListOf<Element> = document.querySelectorAll('#side-menu a');

  sideMenuLinks.forEach((element: Element) => {
    element.addEventListener('click', () => {
      if (!desktopView()) {
        header.classList.remove('active');
        document.body.classList.remove('max-venus:overflow-hidden');
      }
    });
  });

  window.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event?.key?.toLowerCase() !== 'escape' || !header.classList.contains('active') || desktopView()) {
      return;
    }

    header.classList.toggle('active');
    document.body.classList.toggle('max-venus:overflow-hidden');
  });
};

const googleTag = (): void => {
  let consentPreferences: Record<string, string> = {};

  const cookieBanner: HTMLElement = document.getElementById('cookie-banner');

  const showCookieBanner = (): void => {
    if (cookieBanner.classList.contains('hidden')) {
      cookieBanner.classList.remove('hidden');
    }
  };

  const hideCookieBanner = (): void => {
    if (!cookieBanner.classList.contains('hidden')) {
      cookieBanner.classList.add('hidden');
    }
  };

  const allConsentGranted = (): void => {
    consentPreferences = {
      ad_personalization: 'granted',
      ad_user_data: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    };

    gtag('consent', 'update', consentPreferences);

    setStorageItem('consent-allowed', true);
    setStorageItem('consent-preferences', consentPreferences);

    hideCookieBanner();
  };

  const allConsentDenied = (): void => {
    gtag('consent', 'update', {
      ad_personalization: 'denied',
      ad_user_data: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });

    setStorageItem('consent-allowed', false);
    removeStorageItem('consent-preferences');

    hideCookieBanner();
  };

  document.getElementById('accept-all-cookie').addEventListener('click', allConsentGranted);

  document.getElementById('reject-all-cookie').addEventListener('click', allConsentDenied);

  document.querySelectorAll('.conversion-link').forEach((conversionLink: Element) => {
    conversionLink.addEventListener('click', () => {
      if (!consentPreferences?.analytics_storage) {
        return;
      }

      gtag('event', 'app_visit');
    });
  });

  const consentAllowed: boolean = getStorageItem('consent-allowed');

  if (typeof consentAllowed !== 'boolean') {
    return showCookieBanner();
  }

  consentPreferences = getStorageItem('consent-preferences');

  if (consentAllowed) {
    return gtag('consent', 'update', consentPreferences);
  }
};

window.addEventListener('load', (): void => {
  header();
  footer();
  googleTag();
});
